@import '../../assets/style/app';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 150;
  padding: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(7, 9, 19, 0);
  transition: 0.3s;

  @media (min-width: 991px) {
    padding: 34px 60px;
  }

  &_scroll {
    padding-top: 16px;
    padding-bottom: 16px;
    background: rgba(7, 9, 19, 1);

    @media (min-width: 991px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &_hide {
    transform: translateY(-101%);
  }

  .logo {
    display: flex;
    align-items: center;

    img {
      display: block;
      width: 135px;
      height: 42px;

      @media (max-width: 767px) {
        width: 120px;
        height: 37px;
      }
      @media (max-width: 340px) {
        width: 100px;
        height: auto;
      }
    }

    p {
      margin-left: 13px;
      @include Outfit;
      font-weight: 500;
      font-size: 23.5556px;
      line-height: 120%;
      color: #ffffff;
    }
  }

  .links {
    display: none;
    position: absolute;
    left: 50%;
    //top: 34px;
    top: 50%;
    //transform: translate(-50%, 0);
    transform: translate(-50%, -50%);
    padding: 10px 24px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 999px;

    @media (min-width: 991px) {
      display: block;
    }

    .link {
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      align-items: center;
      color: #ffffff;
      transition: 0.3s;

      &:not(:first-child) {
        margin-left: 24px;
      }

      &:hover {
        color: #d1d1db;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    .launchBtn {
      & > div {
        & > div {
          height: 42px;
          @media (max-width: 1440px) {
            height: 38px;
          }
        }
      }
    }

    .burgerBtn {
      display: block;
      @include centerRow;
      @include button;
      margin-left: 8px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #fff;

      @media (min-width: 991px) {
        display: none;
      }
    }
  }
}

@import '../../assets/style/app';

.revolutionize {
  padding-top: 100px;
  padding-bottom: 100px;

  @media (min-width: 991px) {
    padding-top: 210px;
    padding-bottom: 0;
    background: linear-gradient(
      180deg,
      #060712 0%,
      #070713 16.99%,
      #070713 79.13%,
      #060712 100%
    );
  }

  .inner {
    .title {
      font-weight: 600;
      font-size: 40px;
      line-height: 110%;
      letter-spacing: -0.04em;
      background: radial-gradient(
          52.4% 104.55% at 53.32% 134.09%,
          rgba(8, 6, 23, 0.72) 0%,
          rgba(17, 18, 31, 0) 100%
        ),
        #ffffff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      @media (min-width: 991px) {
        font-size: 60px;
        width: 753px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .content {
      position: relative;

      @media (min-width: 991px) {
        padding-top: 80px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
      }

      .animationWrapper {
        //box-shadow: 0 0 34px 40px rgb(7,7,19);
        pointer-events: none;
        // 1.1349
        width: 264px;
        height: 299px;
        margin-left: auto;
        margin-right: auto;

        @include mobile360 {
          width: 304px;
          height: 345px;
        }

        @media (min-width: 991px) {
          margin-top: -55px;
          margin-left: 44px;
          width: 612px;
          height: 700px;

          margin-left: auto;
          margin-right: 0;
        }

        @media (max-width: 1100px) {
          width: 450px;
          height: 515px;
          margin-left: 0;
          transform: translate(40px, 0);
        }
        @media (max-width: 990px) {
          display: none;
        }

        video {
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
        }

        img {
          object-fit: contain;
          object-position: center;
          width: 100%;
          height: 100%;
        }

        canvas {
          object-fit: cover;
          object-position: 40% 0%;
          width: 100%;
          height: 100%;
        }
      }

      .cards {
        margin-top: 40px;

        @media (min-width: 991px) {
          position: absolute;
          left: 0;
          margin-top: 0;
          width: 580px;
        }

        .card {
          position: relative;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.03) 0%,
            rgba(255, 255, 255, 0) 104.6%
          );
          border-radius: 20px;
          border: 1px solid rgba(213, 210, 230, 0.08);
          padding: 24px;
          transition: all 0.3s ease;
          overflow: hidden;

          &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;

            background: linear-gradient(
              123.44deg,
              #3202e7 27.83%,
              #a78be2 86.58%
            );

            border-radius: 19px;
            overflow: hidden;

            opacity: 0;
            transition: all 0.3s ease;
          }

          &._active {
            box-shadow: 0px 30px 100px rgba(78, 25, 190, 0.2);

            &::before {
              opacity: 1;
            }
          }

          @media (min-width: 991px) {
            padding: 32px;
          }

          &:not(:first-child) {
            margin-top: 20px;
          }

          .cardTitle {
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;
            color: #ffffff;

            @media (min-width: 991px) {
              font-size: 24px;
            }
          }

          .cardDescription {
            margin-top: 20px;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #d1d1db;

            @media (min-width: 991px) {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

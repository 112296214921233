.links {
  @media (max-width: 767px) {
    display: none;
  }

  &__title {
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: #ffffff;
  }

  &Body {
    margin: 8px 0 0;
    display: flex;
    align-items: center;
  }

  &__item {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    transition: all 0.4s ease;

    &:hover {
      border: 1px solid #fff;
    }

    &:not(:first-child) {
      margin: 0 0 0 6px;
    }
  }
}

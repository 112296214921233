.text {
  &__label {
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 100%;
    }
  }
  &__title {
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 90px;
    line-height: 110%;
    letter-spacing: -0.04em;
    text-align: center;
    color: #fff;

    @media (max-width: 767px) {
      font-size: 64px;
      line-height: 110%;
    }

    span {
      background: linear-gradient(90deg, #5768ff -3.57%, #8d57ff 50%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin: 0 16px 0 0;

      @media (max-width: 767px) {
        margin: 0 8px 0 0;
      }
    }
  }
  &__subtitle {
    margin: 20px 0 0;
    font-family: 'Manrope';
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    text-align: center;

    @media (max-width: 767px) {
      margin: 16px 0 0;
      font-size: 14px;
      line-height: 150%;
    }
  }
}

@import '../../assets/style/app';

.callTo {
  padding: 90px 8px 60px;

  @media (min-width: 991px) {
    padding: 160px 0 60px;
  }

  .blueCard {
    position: relative;
    box-sizing: border-box;
    border-radius: 20px;
    background: radial-gradient(
        143.14% 101.21% at 47.97% 2.55%,
        rgba(12, 12, 21, 0) 59.23%,
        rgba(51, 24, 182, 0.8) 82.16%,
        rgba(139, 85, 255, 0.8) 94.48%,
        rgba(175, 140, 252, 0.8) 100%
      ),
      #090914;
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before {
      content: '';
      display: block;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid rgba($color: #fff, $alpha: 0.1);
      border-radius: 39px;
    }

    @media (min-width: 991px) {
      border-radius: 30px;
      padding: 80px 0;
    }

    .title {
      font-weight: 600;
      font-size: 40px;
      line-height: 110%;
      text-align: center;
      letter-spacing: -0.04em;
      background: radial-gradient(
          105.67% 212.12% at 41.3% -187.12%,
          rgba(8, 6, 23, 0.72) 0%,
          rgba(17, 18, 31, 0) 100%
        ),
        #ffffff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (min-width: 991px) {
        font-size: 52px;
      }
    }

    .description {
      margin-top: 34px;
      font-weight: 400;
      font-size: 15px;
      line-height: 150%;
      text-align: center;
      color: #fff;

      @media (min-width: 991px) {
        font-size: 15px;
        // width: 380px;
        max-width: 660px;
      }
    }

    .joinBtn {
      flex: 0 0 auto;
      margin-top: 30px;
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;800&family=Outfit:wght@500&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
}

body {
  height: 100vh;
}

.container {
  max-width: 1216px;
  padding: 0 18px;
  margin: 0 auto;
}

.scroller {
  overflow-x: hidden;
}

//========= FONTS ===========//

@mixin Outfit {
  font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 500;
}

//========= BREAK POINTS ===========//
@mixin mobile360 {
  @media all and (min-width: 360px) {
    @content;
  }
}

@mixin desktop {
  @media all and (min-width: 1440px) {
    @content;
  }
}

@mixin onlyMobile($displayType: block) {
  display: $displayType;
  @content;

  @include desktop {
    display: none;
  }
}

@mixin onlyDesktop($displayType: block) {
  display: none;

  @include desktop {
    display: $displayType;
    @content;
  }
}

//========= OTHER ===========//
@mixin centerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin centerRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin inner {
  padding-left: 28px;
  padding-right: 28px;

  @include desktop {
    padding-left: 0;
    padding-right: 0;
    width: 1180px;
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin button_contained {
  @include button;
  background: #FFF;
  border-radius: 999px;
  padding: 10px 24px;
  transition: 0.3s;

  @include desktop {
    padding: 13px 29px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.04em;
    color: #01090B;

    @include desktop {
      font-size: 15px;
    }
  }

  &:hover {
    background: #E5E5ED;
  }

}

@mixin button_outlined {
  @include button;
  border: 1px solid #FFFFFF;
  background: rgba(229, 229, 237, 0);
  border-radius: 999px;
  padding: 10px 24px;
  transition: 0.3s;

  @include desktop {
    padding: 13px 29px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.04em;
    color: #FFF;
    transition: 0.3s;

    @include desktop {
      font-size: 15px;
    }
  }

  &:hover {
    background: rgba(229, 229, 237, 1);

    p {
      color: #070913;
    }
  }
}



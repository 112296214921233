@import '../../../assets/style/app';

.links {
  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #89898f;
  }

  .nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;

    @media (min-width: 991px) {
      margin-top: 24px;
    }

    .link {
      position: relative;
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: #d1d1db;

      &:not(:first-child) {
        margin-top: 20px;

        @media (min-width: 991px) {
          margin-top: 20px;
        }
      }

      &:after {
        @media (min-width: 991px) {
          display: block;
          content: '';
          position: absolute;
          left: 0;
          bottom: -4px;
          height: 1px;
          width: 0;
          background: #d1d1db;
          transition: 0.3s;
        }
      }

      &:hover {
        &:after {
          @media (min-width: 991px) {
            width: 100%;
          }
        }
      }
    }
  }
}

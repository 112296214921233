@import '../../assets/style/app';

.distribution {
  padding-top: 100px;

  @media (min-width: 767px) {
    padding-top: 200px;
  }

  .inner {
    .title {
      font-weight: 600;
      font-size: 40px;
      line-height: 110%;
      text-align: center;
      letter-spacing: -0.04em;
      background: radial-gradient(
          39.08% 90.11% at -8.58% 31.25%,
          rgba(8, 6, 23, 0.72) 0%,
          rgba(17, 18, 31, 0) 100%
        ),
        #ffffff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (min-width: 767px) {
        font-size: 60px;
      }
    }

    .content {
      @media (min-width: 767px) {
        margin-top: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .animWrapper {
        width: 264px;
        height: 264px;
        margin-left: auto;
        margin-right: auto;

        @include mobile360 {
          width: 326px;
          height: 324.8px;
        }

        @media (min-width: 767px) {
          width: 544px;
          height: 542px;
          margin-left: 0;
          margin-right: 0;
        }

        video {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        img {
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
        }
      }

      .listCard {
        margin-top: 5px;
        padding: 30px 24px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.02) 0%,
          rgba(255, 255, 255, 0) 104.6%
        );
        border-radius: 20px;
        border: 1px solid rgba(213, 210, 230, 0.08);

        @media (min-width: 767px) {
          margin-top: 0;
          padding: 0;
          background: none;
          border: none;
        }

        .listTitle {
          font-weight: 800;
          font-size: 13px;
          line-height: 100%;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #d1d1db;

          @media (min-width: 767px) {
            font-size: 16px;
          }
        }

        .list {
          margin-top: 30px;

          @media (min-width: 767px) {
            margin-top: 40px;
          }

          .listItem {
            display: flex;

            &:not(:first-child) {
              margin-top: 30px;
            }

            .dot {
              flex: 0 0 auto;
              position: relative;
              margin-top: 3px;
              width: 19px;
              height: 17px;
              border-radius: 6px;

              @media (min-width: 767px) {
                width: 22px;
                height: 20px;
              }

              &:after {
                position: absolute;
                display: block;
                content: '';
                left: 0;
                top: -3px;
                width: 19px;
                height: 17px;
                border-radius: 6px;

                @media (min-width: 767px) {
                  width: 22px;
                  height: 20px;
                }
              }
            }

            .texts {
              margin-left: 12px;

              @media (min-width: 767px) {
                margin-left: 18px;
              }

              .value {
                font-weight: 400;
                font-size: 22px;
                line-height: 100%;
                color: #ffffff;

                @media (min-width: 767px) {
                  font-size: 24px;
                }
              }

              .label {
                margin-top: 8px;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: #d1d1db;

                @media (min-width: 767px) {
                  margin-top: 10px;
                  font-size: 15px;
                }
              }
            }

            &:nth-child(1) {
              .dot {
                background: #1f00b7;

                &:after {
                  background: #2b00ff;
                  box-shadow: 0px 6px 30px rgba(43, 0, 255, 0.7);
                }
              }
            }

            &:nth-child(2) {
              .dot {
                background: #7002c6;

                &:after {
                  background: #8f00ff;
                  box-shadow: 0px 6px 30px rgba(143, 0, 255, 0.7);
                }
              }
            }

            &:nth-child(3) {
              .dot {
                background: #bebebe;

                &:after {
                  background: #ffffff;
                  box-shadow: 0px 6px 30px rgba(255, 255, 255, 0.4);
                }
              }
            }

            &:nth-child(4) {
              .dot {
                background: #626271;

                &:after {
                  background: #9e9eb1;
                  box-shadow: 0px 6px 30px rgba(186, 186, 204, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
}

.button {
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  background: transparent;
  border: 1px solid #4e4f60;
  border-radius: 40px;

  .inner {
    position: relative;
    overflow: hidden;
    border-radius: 40px;
    padding: 1px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 10px;
      width: 60px;
      height: 30px;

      opacity: 0;

      background: linear-gradient(
        90deg,
        rgba(217, 217, 217, 0) 0%,
        #d9d9d9 23.85%,
        #d9d9d9 79.2%,
        rgba(217, 217, 217, 0) 100%
      );
    }
  }

  &:hover {
    animation: BtnHoverShadowDefault 1s 1 forwards;

    .inner {
      &::before {
        animation: BtnHover 1s 1 forwards;
      }
    }
  }

  .wrapper {
    position: relative;
    z-index: 1;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    background: #0b0c17;

    padding: 0 20px;
    border-radius: 40px;

    font-family: 'Manrope';
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;
    leading-trim: both;
    text-edge: cap;
    letter-spacing: -0.04em;
    color: #ffffff;

    svg {
      margin: 2px 0 0 5px;
    }
  }

  &_blue {
    border: none;

    .wrapper {
      background: transparent;
      background: #483ae2;
    }

    &:hover {
      animation: unset;
      .wrapper {
        animation: BtnHoverShadowBlue 1s 1 forwards;
      }

      .inner {
        &::before {
          animation: BtnHover 1s 1 forwards;
        }
      }
    }
  }
}

@keyframes BtnHover {
  0% {
    left: 10px;
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
  25% {
    left: calc(100% - 60px);
    opacity: 1;
    top: -5px;
    transform: rotate(0deg);
  }
  50% {
    top: 4px;
    left: calc(100% - 30px);
    opacity: 1;
    transform: rotate(90deg);
  }
  51% {
    top: calc(100% - 15px);
    transform: rotate(180deg);
  }
  75% {
    top: calc(100% - 15px);
    left: 10px;
    opacity: 1;
    transform: rotate(180deg);
  }
  76% {
  }
  90% {
    top: 0;
    left: -30px;
    opacity: 1;
    transform: rotate(270deg);
    opacity: 0;
  }
  100% {
    box-shadow: inset 0px 0px 5px 1px rgba(255, 255, 255, 0.8);
  }
}

@keyframes BtnHoverShadowDefault {
  0% {
    box-shadow: unset;
  }
  65% {
    box-shadow: unset;
  }
  70% {
    box-shadow: 0px 0px 4px 1px rgba(255, 255, 255, 0.1);
  }
  90% {
    box-shadow: 0px 0px 4px 1px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0px 0px 4px 1px rgba(255, 255, 255, 0.8);
  }
}
@keyframes BtnHoverShadowBlue {
  0% {
    box-shadow: unset;
  }
  65% {
    box-shadow: unset;
  }
  70% {
    box-shadow: inset 0px 0px 5px 1px rgba(255, 255, 255, 0.1);
  }
  90% {
    box-shadow: inset 0px 0px 5px 1px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: inset 0px 0px 5px 1px rgba(255, 255, 255, 0.8);
  }
}

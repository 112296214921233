.buy {
  margin: 100px 0 0;

  @media (max-width: 991px) {
    margin: 60px 0 0;
  }

  &Body {
    display: flex;
    border-radius: 20px;
    background: linear-gradient(180deg, #0b0c18 0%, #070814 100%);
    padding: 40px 30px 40px 40px;

    @media (max-width: 991px) {
      flex-direction: column;
      padding: 24px;
    }

    &__info {
      flex: 0 0 auto;
    }
    &__divider {
      margin: 0 50px;
      background: linear-gradient(
        27deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.08) 17.56%,
        rgba(255, 255, 255, 0.08) 83.06%,
        rgba(255, 255, 255, 0) 100%
      );
      min-height: 100%;
      width: 1px;
      flex: 0 0 auto;

      @media (max-width: 991px) {
        display: none;
      }
    }
    &__text {
      flex: 1 1 auto;
      color: #d1d1db;
      font-family: Manrope;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      @media (max-width: 991px) {
        margin: 30px 0 0;
        font-size: 14px;
      }
    }
    &__row {
      display: flex;
      align-items: center;

      @media (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
      }

      &:not(:first-child) {
        margin: 44px 0 0;
        @media (max-width: 991px) {
          margin: 22px 0 0;
        }
      }
      &:nth-child(2) {
        .buyBody__rowValue {
          background: linear-gradient(
            -320deg,
            rgba(102, 72, 162, 1) 10%,
            rgba(39, 23, 113, 1) 25%,
            rgba(9, 10, 22, 1) 40%
          );
          @media (max-width: 991px) {
            background: linear-gradient(
              135deg,
              rgba(102, 72, 162, 1) 10%,
              rgba(39, 23, 113, 1) 40%,
              rgba(9, 10, 22, 1) 70%
            );
          }
        }
      }

      &Title {
        color: #fff;
        font-family: Manrope;
        font-size: 60px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        letter-spacing: -2.4px;
        background: radial-gradient(
            52.4% 104.55% at 53.32% 134.09%,
            rgba(8, 6, 23, 0.72) 0%,
            rgba(17, 18, 31, 0) 100%
          ),
          #ffffff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        @media (max-width: 991px) {
          font-size: 40px;
          letter-spacing: -1.6px;
        }
      }

      &Value {
        flex: 0 0 auto;
        display: inline-flex;
        align-items: center;
        margin: 0 0 0 20px;
        padding: 0 16px;
        height: 54px;
        border-radius: 16px;
        background: linear-gradient(
          135deg,
          rgba(102, 72, 162, 1) 10%,
          rgba(39, 23, 113, 1) 40%,
          rgba(9, 10, 22, 1) 70%
        );

        color: #fff;
        leading-trim: both;
        text-edge: cap;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        letter-spacing: -0.64px;

        @media (max-width: 991px) {
          margin: 14px 0 0;
          font-size: 14px;
          letter-spacing: -0.56px;
          line-height: 150%;
        }
      }
    }
  }
}

@import '../../assets/style/app';

.weWorkWith {
  // margin-top: 100px;

  @media (min-width: 991px) {
    margin-top: 160px;
  }

  .inner {
    .title {
      text-align: center;
      font-weight: 800;
      font-size: 13px;
      line-height: 100%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #d1d1db;

      @media (min-width: 991px) {
        font-size: 16px;
      }
    }

    .card {
      // border: 1px solid rgba(213, 210, 230, 0.08);
      // background: linear-gradient(
      //   180deg,
      //   rgba(255, 255, 255, 0.04) 0%,
      //   rgba(255, 255, 255, 0) 104.6%
      // );
      // border-radius: 16px;

      margin-top: 24px;
      padding: 17px 0;

      @media (min-width: 991px) {
        margin-top: 40px;
        padding: 42px 0;
        border-radius: 20px;
      }

      .rowMobile1 {
        display: none;
        grid-template-columns: repeat(3, 1fr);

        @media (max-width: 990px) {
          display: grid;
        }

        .cell {
          @include centerRow;
          position: relative;

          &:not(:first-child) {
            &:before {
              position: absolute;
              display: block;
              content: '';
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              height: 24px;
              width: 1px;
              background: linear-gradient(
                rgba(40, 40, 44, 0),
                rgba(40, 40, 44, 1),
                rgba(40, 40, 44, 1),
                rgba(40, 40, 44, 0)
              );
            }
          }

          &:nth-child(1) {
            svg {
              width: 70.47px;
              height: 18.67px;
            }
          }

          &:nth-child(2) {
            svg {
              width: 50.87px;
              height: 18.67px;
            }
          }

          &:nth-child(3) {
            svg {
              width: 79.8px;
              height: 16.8px;
            }
          }
        }
      }

      .rowMobile2 {
        display: none;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 16px;

        @media (max-width: 990px) {
          display: grid;
        }

        .cell {
          position: relative;

          &:nth-child(1) {
            padding-right: 28px;
            display: flex;
            justify-content: flex-end;
          }

          &:nth-child(2) {
            padding-left: 28px;
            display: flex;
            justify-content: flex-start;

            &:before {
              position: absolute;
              display: block;
              content: '';
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              height: 24px;
              width: 1px;
              background: linear-gradient(
                rgba(40, 40, 44, 0),
                rgba(40, 40, 44, 1),
                rgba(40, 40, 44, 1),
                rgba(40, 40, 44, 0)
              );
            }
          }

          svg {
            height: 18px;
          }
        }
      }

      .rowDesktop {
        display: none;
        grid-template-columns: repeat(5, 1fr);

        @media (min-width: 991px) {
          display: grid;
        }

        .cell {
          @include centerRow;
          position: relative;

          &:not(:first-child) {
            &:before {
              position: absolute;
              display: block;
              content: '';
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              height: 74px;
              width: 1px;
              background: linear-gradient(
                rgba(40, 40, 44, 0),
                rgba(40, 40, 44, 1),
                rgba(40, 40, 44, 1),
                rgba(40, 40, 44, 0)
              );
            }
          }

          svg {
            height: 36px;
          }
        }
      }

      svg {
        cursor: pointer;
        opacity: 0.4;
        transition: 0.3s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

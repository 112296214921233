@import '../../../assets/style/app';

.slide {
  position: relative;
  cursor: pointer;
  border-radius: 20px;
  transition: 0.3s;
  padding: 1px;

  @media (max-width: 991px) {
    height: 100%;
  }

  &:before {
    box-sizing: border-box;
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    pointer-events: none;
    border-radius: 20px;

    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.08) 100%
    );
  }

  .wrapper {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding: 30px;
    height: 100%;
    display: flex;
    background: linear-gradient(180deg, #0b0c18 0%, #070814 100%);
    border-radius: 20px;

    @media (max-width: 991px) {
      padding: 24px;
      display: block;
    }
  }

  @media (min-width: 991px) {
    &:hover {
      .wrapper {
        background: linear-gradient(180deg, #10111c 0%, #0d0e17 100%);
      }
    }
  }

  &_active {
    .wrapper {
      background: linear-gradient(180deg, #10111c 0%, #0d0e17 100%);
    }
  }

  .top {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;

    img {
      width: 110px;
      height: 110px;
      border-radius: 16px;

      @media (min-width: 991px) {
        width: 120px;
        height: 120px;
      }
    }

    .socials {
      display: flex;

      @media (min-width: 991px) {
        display: none;
      }

      .link {
        width: 24px;
        height: 24px;

        svg {
          width: 24px;
          height: 24px;

          path {
            fill: #d1d1db;
          }
        }

        &:not(:first-child) {
          margin-left: 6px;
        }
      }
    }
  }

  .bottom {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    @media (min-width: 991px) {
      margin-top: 0;
      margin-left: 30px;
    }

    .name {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 100%;
      color: #fff;

      @media (min-width: 991px) {
        font-size: 24px;
      }
    }

    .job {
      position: relative;
      align-self: flex-start;
      margin-top: 20px;
      padding: 8px 10px 0;
      background: linear-gradient(
        90.19deg,
        rgba(108, 78, 255, 1),
        rgba(255, 255, 255, 1)
      );
      border-radius: 36px;

      &:after {
        display: block;
        content: '';
        z-index: 1;
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 1px;
        right: 1px;
        border-radius: 35px;
        background: rgb(20, 20, 38);
      }

      p {
        position: relative;
        z-index: 2;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        background: linear-gradient(90deg, #6c4eff, #ffffff), #ffffff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-bottom: 9px;
      }
    }

    .description {
      margin-top: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #d1d1db;

      @media (min-width: 991px) {
        margin-top: 18px;
        font-size: 15px;
      }
    }

    .socials {
      display: none;
      margin-top: 20px;

      @media (min-width: 991px) {
        display: flex;
      }

      .link {
        width: 24px;
        height: 24px;

        &:not(:first-child) {
          margin-left: 6px;
        }

        svg {
          width: 24px;
          height: 24px;

          path {
            transition: 0.3s;
            fill: #d9d9d9;
          }
        }

        &:hover {
          svg path {
            fill: #fff;
          }
        }
      }
    }
  }
}

@import '../../../assets/style/app';

.logo {
  .label {
    @include Outfit;
    font-weight: 500;
    font-size: 29.913px;
    line-height: 120%;
    color: #fff;

    img {
      width: 135px;
    }

    @include desktop {
      font-size: 32px;
    }
  }

  .text {
    display: none;
    margin-top: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #89898f;
    width: 410px;

    @media (min-width: 991px) {
      display: block;
    }
  }
}

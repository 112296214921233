.staking {
  position: relative;
  margin: 180px 10px 0;

  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    margin: 100px 8px 0;
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 410px;
    height: calc(50% + 76px);
    transform: translate(-50%, -100%);
    padding: 1px 1px 0;

    font-family: 'Manrope';
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.04em;
    color: #7a6fff;

    br {
      display: none;
    }

    @media (max-width: 767px) {
      box-sizing: border-box;
      flex: 0 0 calc(50% - 42px);
      height: auto;
      transform: translate(0);
      width: auto;
      left: auto;
      top: auto;
      position: relative;
      font-size: 18px;
      line-height: 150%;
      padding: 0 10px !important;
      text-align: center;

      &:not(:first-child) {
        margin: 0 0 0 auto;
      }

      br {
        display: block;
      }
    }

    .wrapper {
      height: 100%;
      padding: 25px 0 0;
      box-sizing: border-box;
      position: relative;
      z-index: 1;
      background: linear-gradient(180deg, #0b0c18 0%, #070814 104.6%);
      border-radius: 20px 20px 0 0;

      @media (max-width: 767px) {
        border-radius: 0;
        background: transparent;
        padding: 0;
        height: auto;
      }
    }

    &::before {
      box-sizing: border-box;
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      pointer-events: none;
      border-radius: 20px 20px 0 0;

      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.08) 100%
      );

      @media (max-width: 767px) {
        border-radius: 0;
        background: transparent;
        padding: 0;
        height: auto;
      }
    }

    &_under {
      transform: translate(-50%, 0);
      padding: 0 1px 1px;

      color: #ffffff;

      @media (max-width: 767px) {
        border-radius: 0;
        background: transparent;
        padding: 0;
        height: auto;
        transform: translate(0);
      }

      .wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        border-radius: 0 0 20px 20px;
        padding: 0 0 25px;
      }

      &::before {
        border-radius: 0 0 20px 20px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.08) 100%
        );

        @media (max-width: 767px) {
          border-radius: 0;
          background: transparent;
          padding: 0;
          height: auto;
        }
      }
    }
  }

  &Stats {
    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
      margin: 10px 0;
      flex-direction: column;
      flex: calc(50% - 42px);
    }

    &__item {
      box-sizing: border-box;
      position: relative;
      z-index: 1;
      flex: 0 1 180px;
      padding: 1px 1px 0;

      font-family: 'Manrope';
      font-weight: 500;
      font-size: 40px;
      line-height: 110%;
      text-align: center;
      letter-spacing: -0.04em;
      color: #7a6fff;

      @media (max-width: 767px) {
        flex: 0 0 auto;
        font-size: 28px;
        line-height: 110%;
        padding: 1px 0 1px 1px;
      }

      .wrapper {
        box-sizing: border-box;
        position: relative;
        z-index: 1;
        padding: 47px 0 48px;
        background: linear-gradient(180deg, #0b0c18 0%, #070814 104.6%);
        border-radius: 20px 20px 0 0;

        @media (max-width: 767px) {
          padding: 19px 0;
          border-radius: 16px 0 0 16px;
        }
      }

      &:not(:first-child) {
        margin: 0 0 0 20px;

        @media (max-width: 767px) {
          margin: 12px 0 0;
        }
      }

      &::before {
        box-sizing: border-box;
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        pointer-events: none;
        border-radius: 20px 20px 0 0;

        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.08) 100%
        );

        @media (max-width: 767px) {
          border-radius: 16px 0 0 16px;
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.08) 0%,
            rgba(255, 255, 255, 0) 50%,
            rgba(255, 255, 255, 0.08) 100%
          );
        }
      }
    }

    &_under {
      .stakingStats__item {
        padding: 0 1px 1px;
        color: #ffffff;

        @media (max-width: 767px) {
          padding: 1px 1px 1px 0;
        }

        .wrapper {
          border-radius: 0 0 20px 20px;
          padding: 48px 0 47px;

          @media (max-width: 767px) {
            padding: 19px 0;
            border-radius: 0 16px 16px 0;
          }
        }
        &::before {
          border-radius: 0 0 20px 20px;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.08) 100%
          );

          @media (max-width: 767px) {
            border-radius: 0 16px 16px 0;
            background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.08) 0%,
              rgba(255, 255, 255, 0) 50%,
              rgba(255, 255, 255, 0.08) 100%
            );
          }
        }
      }
    }
  }

  &Line {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background: linear-gradient(95.85deg, #3202e7 10.05%, #a78be2 106.61%);
    opacity: 0.9;
    border-radius: 10px;

    @media (max-width: 767px) {
      flex-direction: column;
      width: 84px;
      height: auto;
    }

    &__item {
      flex: 0 1 180px;
      font-family: 'Manrope';
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      text-align: center;
      color: #ffffff;

      @media (max-width: 767px) {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        height: 32px;
        font-size: 14px;
      }

      &:not(:first-child) {
        margin: 0 0 0 20px;

        @media (max-width: 767px) {
          margin: 52px 0 0;
        }
      }
    }
  }
}

@import '../../../assets/style/app';

.joinUs {
  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #89898f;
  }

  .links {
    margin-top: 20px;
    display: flex;

    @media (min-width: 991px) {
      margin-top: 24px;
    }

    .link {
      @include centerRow;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.2);

      &:not(:first-child) {
        margin-left: 8px;
      }

      svg {
        width: 30px;
        height: 30px;

        path {
          transition: 0.3s;
          fill: #d9d9d9;
        }
      }

      &:hover {
        svg path {
          fill: #fff;
        }
      }
    }
  }
}

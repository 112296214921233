@import '../../assets/style/app';

.teamSlider {
  padding-top: 124px;

  @media (min-width: 991px) {
    padding-top: 110px;
  }

  .sliderItem {
    padding: 0 0 2px;
  }

  .title {
    font-weight: 600;
    font-size: 40px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.04em;
    background: radial-gradient(
        39.08% 90.11% at -8.58% 31.25%,
        rgba(8, 6, 23, 0.72) 0%,
        rgba(17, 18, 31, 0) 100%
      ),
      #ffffff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (min-width: 991px) {
      font-size: 60px;
    }
  }

  .swiperMobile {
    display: block;
    margin-top: 30px;

    @media (min-width: 991px) {
      display: none;
    }

    .swiperSlide {
      width: 275px;
      height: auto;
    }
  }

  .cardsDesktop {
    display: none;
    margin-top: 50px;

    @media (min-width: 991px) {
      display: block;
    }

    .inner {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 22px 20px;
    }
  }
}

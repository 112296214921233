@import "../../assets/style/app";

.burgerMenu {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 0;
  z-index: 100;
  background: #070913;
  transition: 0.5s;
  overflow: hidden;

  &_open {
    height: 100vh;
  }

  .inner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 28px 28px 30px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        display: flex;
        align-items: center;

        img {
          display: block;
          width: 38px;
          height: 38px;
        }

        p {
          margin-left: 13px;
          @include Outfit;
          font-weight: 500;
          font-size: 23.5556px;
          line-height: 120%;
          color: #FFFFFF;
        }
      }

      .right {
        display: flex;
        align-items: center;

        .launch {
          @include button_outlined;

          padding: 10px 16px;

          @include desktop {
            padding: 10px 16px;
          }
        }

        .burgerBtn {
          @include centerRow;
          @include button;
          margin-left: 8px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #FFF;
        }
      }
    }

    .links {
      position: absolute;
      left: 28px;
      top: 148px;
      display: flex;
      flex-direction: column;

      .link {
        text-decoration: none;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #FFFFFF;

        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }



  .socialLinksWrapper {

    .label {
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: #89898F;
    }

    .socialLinks {
      margin-top: 16px;
      display: flex;

      .link {
        @include centerRow;
        width: 36px;
        height: 36px;
        border-radius: 50%;

        &:not(:first-child) {
          margin-left: 6px;
        }
      }
    }
  }

}

.select {
  position: relative;
  z-index: 2;

  &.isOpen {
    .selectList {
      max-height: 250px;
      border-color: #1f202a;
      opacity: 1;
      pointer-events: unset;
    }
  }

  &Btn {
    cursor: pointer;
    position: relative;
    border: 1px solid #4e4f60;
    border-radius: 40px;
    padding: 15px 16px 15px 20px;

    & > svg {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translate(0, -50%);
    }

    &__label {
      position: absolute;
      top: -10px;
      left: 18px;
      padding: 0 2px;
      background: #0b0c17;

      font-family: 'Manrope';
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: #878789;
    }

    &__selected {
      font-family: 'Manrope';
      font-weight: 500;
      font-size: 15px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: #ffffff;
    }
  }
  &List {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    top: calc(100% + 4px);
    background: #0e0f1b;
    border: 1px solid #1f202a;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    padding: 16px 23px;

    max-height: 0;
    border-color: transparent;
    opacity: 0;
    pointer-events: none;

    transition: all 0.4s ease;

    &::before {
      content: '';
      display: block;
      height: 6px;
      position: absolute;
      top: -5px;
      left: 0;
      right: 0;
      background: transparent;
    }

    &__item {
      cursor: pointer;
      font-family: 'Manrope';
      font-weight: 500;
      font-size: 15px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: #ffffff;

      &:not(:first-child) {
        margin: 20px 0 0;
      }

      &.isSelected {
        cursor: default;
        color: #4b4b5c;
      }
    }
  }
}

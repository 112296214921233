@import '../../assets/style/app';

.invest {
  padding-top: 50px;

  @media (min-width: 991px) {
    padding-top: 76px;
  }

  .investInner {
    display: flex;
    flex-direction: column;

    @media (min-width: 991px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .videoWrapper {
      // 1.4936
      width: 264px;
      height: 394px;
      margin-left: auto;
      margin-right: auto;

      @include mobile360 {
        width: 312px;
        height: 466px;
      }

      @media (min-width: 991px) {
        width: 498px;
        height: 746px;
        margin-left: 0;
        margin-right: 0;
      }

      video {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }

    .second {
      margin-top: 21px;

      @media (min-width: 991px) {
        margin-top: 0;
        width: 580px;
      }

      .title {
        font-weight: 600;
        font-size: 40px;
        line-height: 110%;
        letter-spacing: -0.04em;
        background: radial-gradient(
            39.08% 90.11% at -8.58% 31.25%,
            rgba(8, 6, 23, 0.72) 0%,
            rgba(17, 18, 31, 0) 100%
          )
          #ffffff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        br {
          display: none;
        }

        @media (min-width: 991px) {
          font-size: 44px;

          background: radial-gradient(
              38.53% 90.9% at 65.15% -19.92%,
              rgba(8, 6, 23, 0.72) 0%,
              rgba(17, 18, 31, 0) 100%
            )
            #ffffff;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;

          br {
            display: block;
          }
        }
      }

      .text {
        margin: 40px 0 0;

        @media (max-width: 991px) {
          margin: 30px 0 0;
        }

        p {
          font-family: 'Manrope';
          font-size: 17px;
          line-height: 150%;
          color: #d1d1db;

          @media (max-width: 991px) {
            font-size: 14px;
            line-height: 150%;
          }

          &:not(:first-child) {
            margin: 14px 0 0;

            @media (max-width: 991px) {
              margin: 10px 0 0;
            }
          }
        }
      }

      .btn {
        margin: 40px 0 0;

        @media (max-width: 991px) {
          margin: 30px 0 0;
        }

        & > div {
          & > div {
            padding: 0 30px;
          }
        }
      }
    }
  }
}

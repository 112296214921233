@import '../../assets/style/app';

.footer {
  padding-bottom: 28px;

  .inner {
    .footerMobile {
      display: block;
      @media (min-width: 991px) {
        display: none;
      }

      .middle {
        margin-top: 36px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .right {
        }
      }
    }

    .footerDesktop {
      display: none;

      @media (min-width: 991px) {
        display: block;
      }

      .top {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .right {
          display: flex;

          .linksWrapper {
            width: 220px;
          }

          .right {
            flex-grow: 1;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }

    .rights {
      margin-top: 50px;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #d1d1db;

      @media (min-width: 991px) {
        margin-top: 30px;
      }
    }
  }
}
